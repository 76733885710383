<template>
  <b-row>
    <!-- left  -->
    <b-col sm="3">
      <b-list-group>
        <b-list-group-item
          ><i class="fa fa-star"></i> <b>Overview</b></b-list-group-item
        >
        <b-list-group-item><a href="">Information</a></b-list-group-item>
      </b-list-group>
      <br />
      <b-list-group>
        <b-list-group-item
          ><i class="fa fa-cogs"></i> <b>Actions</b></b-list-group-item
        >
        <b-list-group-item v-for="(row, id) in actions" :key="id">
          <a :href="row.aksi">{{ row.name }}</a>
        </b-list-group-item>
      </b-list-group>
    </b-col>

    <!-- right -->
    <b-col sm="9">
      <b-row>
        <b-col sm="7">
          <b-card>
            <div class="text-center">
              <i class="fa fa-user-circle fa-4x"></i>
              <br />
              <br />
              <h4>Sectigo PositiveSSL EV</h4>
              <h5>SSL Sectigo</h5>
            </div>
            <template v-slot:footer>
              <b-button variant="success" block>Active</b-button>
              <b-button variant="danger" block>Request Cancellation</b-button>
            </template>
          </b-card>
        </b-col>
        <b-col sm="5">
          <RWCard>
            <template v-slot:body>
              <div class="text-center">
                <h5>Registration Date</h5>
                <h6>21/10/2019</h6>
                <h5>Recurring Amount</h5>
                <h6>Rp 1.799.000,00</h6>
                <h5>Billing Cycle</h5>
                <h6>Annually</h6>
                <h5>Next Due Date</h5>
                <h6>21/10/2020</h6>
                <h5>Payment Method</h5>
                <h6>KlikBCA</h6>
              </div>
            </template>
          </RWCard>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  components: {
    RWCard
  },
  data() {
    return {
      actions: [
        {
          name: "Request Cancellation",
          aksi: ""
        }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Products & Services" },
      { title: "Detail Default" }
    ]);
  }
};
</script>
